/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppProps } from 'next/app';
import Error from 'next/error';
import React from 'react';
import AppProvider from '../src/components/Providers/appProvider';
import { LocaleProvider } from '../src/components/Providers/localeProvider';
import { initSentry } from '../src/lib/sentry';
import '../src/styles/globals.css';

initSentry();

const App: React.FC<AppProps & { err: any }> = ({ Component, pageProps, err }) => {
  return (
    <LocaleProvider locale={pageProps?.locale}>
      <AppProvider>
        {pageProps?.errorStatus ? (
          <Error statusCode={pageProps?.errorStatus} />
        ) : (
          <Component {...pageProps} err={err || pageProps?.err} />
        )}
      </AppProvider>
    </LocaleProvider>
  );
};

export default App;
